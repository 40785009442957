import { useRuntimeConfig } from '#app';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useRuntimeConfig();
  const state = useState('maintenanceMode', () => false);

  // Проверка на стороне клиента
  if (process.client) {
    const hostname = window.location.hostname;

    if (hostname === 'yourservice.ae' && config.public.maintenance && to.path !== '/coming-soon') {
        state.value = true;
    }
  }

  // Проверка на стороне сервера
  if (process.server) {
    const headers = useRequestHeaders(['host']);
    const hostname = headers.host;

    if (hostname === 'yourservice.ae' && config.public.maintenance && to.path !== '/coming-soon') {
        state.value = true;
    }
  }

  if (state.value && to.path !== '/coming-soon') {
    return navigateTo('/coming-soon', { replace: true });
  }
});